<form class="dialog-wrap popup">
  <nb-card class="small-header popup" [nbSpinner]="processing">
    <nb-card-header>
      <ngx-card-header [size]="size" [icon]="favicon" [title]="title ? title : ('Import thông tin sản phẩm' | translate:{definition: '', action: commonService.translate.instant('Common.assign')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

      <!-- <nav>
        {{'AdminProduct.assignCategories' | translate | headtitlecase}}
        <button nbButton status="warning" hero size="tiny" style="float: right;" title="Đóng" (click)="close()">
          <nb-icon pack="eva" icon="close"></nb-icon>
        </button>
      </nav> -->
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <button type="button" nbButton status="danger" hero size="small"
              (click)="chooseFile.click() && false" [disabled]="processing"
              title="{{'Common.choose' | translate | headtitlecase}}" style="float: right; margin-bottom: 1rem;">
              <nb-icon pack="eva" icon="file"></nb-icon>{{'Common.upload' | translate | headtitlecase}}
            </button>
            <input type="file" #chooseFile (change)="onFileChange($event)" style="display: none">
            <label class="label">{{'Danh sách sản phẩm sẽ được import' | translate | headtitlecase}}</label>
            <div class="ag-grid-wrap" style="height: 500px; border: #ccc 1px solid; border-radius: 3px; overflow: hidden;">
              <ag-grid-angular #agGrid style="width: 100%; height: 100%;" id="myGrid" class="ag-theme-balham{{themeName ? ('-'+themeName) : ''}}" [modules]="modules" [columnDefs]="columnDefs" [debug]="true" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [rowModelType]="rowModelType" [paginationPageSize]="paginationPageSize" [cacheOverflowSize]="cacheOverflowSize" [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" [infiniteInitialRowCount]="infiniteInitialRowCount" [maxBlocksInCache]="maxBlocksInCache" [cacheBlockSize]="cacheBlockSize" [getRowNodeId]="getRowNodeId" [components]="components" [rowData]="rowData" (gridReady)="onGridReady($event)" [multiSortKey]="multiSortKey" [rowDragManaged]="rowDragManaged" [getRowHeight]="getRowHeight" [rowHeight]="rowHeight" (columnResized)="onColumnResized()" (rowSelected)="onRowSelected()" [pagination]="pagination" [getRowStyle]="getRowStyle" (cellDoubleClicked)="cellDoubleClicked($event)">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="buttons-row" style="text-align: right;">
        <button nbButton status="warning" hero>{{'Common.close' | translate | headtitlecase}}</button>
        <button type="button" nbButton status="primary" [disabled]="processing" hero (click)="importProducts()">{{'Import' | translate | headtitlecase}}</button>
        <!--<button type="button" nbButton status="danger" [disabled]="!categoriesFormControl.value || processing" hero (click)="revokeCategories()">{{'Common.revoke' | translate | headtitlecase}}</button> -->
      </div>
    </nb-card-footer>
  </nb-card>
</form>